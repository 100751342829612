@import "../../global.scss";

.container {
	@include click;

	&.grey {
		@include color("grey-600");
	}

	&.grey-100 {
		@include color("grey-100");
	}

	&.grey-200 {
		@include color("grey-200");
	}

	&.grey-300 {
		@include color("grey-300");
	}

	&.grey-400 {
		@include color("grey-400");
	}

	&.grey-500 {
		@include color("grey-500");
	}

	&.pink {
		@include color("pink-primary");
	}

	&.blue {
		@include color("blue-primary");
	}

	&.red {
		@include color("red");
	}

	&.orange {
		@include color("orange");
	}

	&.body1 {
		font-size: 18px;
		line-height: 150%;
	}

	&.body2 {
		font-size: 16px;
		line-height: 150%;
	}

	&.body3 {
		font-size: 14px;
		line-height: 150%;
	}

	&.body4 {
		font-size: 12px;
		line-height: 130%;
	}

	&.body5 {
		font-size: 10px;
		line-height: 110%;
	}

	&.trim1 {
		@include trim;
	}

	&.trim {
		@include trim(2);
	}

	&.bold {
		font-weight: 600;
	}
}
