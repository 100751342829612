@use "sass:map";
@import "../../global.scss";

$inputHeight: 40px;

.inputRow {
	display: flex;
	align-items: center;

	&.center {
		justify-content: center;
	}

	&.right {
		justify-content: right;
	}

	&.between {
		justify-content: space-between;
	}

	&.around {
		justify-content: space-around;
	}

	:global(.space) + :global(.space) {
		margin-top: 0;
		margin-left: 16px;
	}

	.textContainer,
	.selectContainer {
		min-width: 150px;
	}

	> .inputHeaderSpace {
		flex: 1;
		@include phone {
			display: none;
		}
	}
}

.labelRow {
	.container {
		.label {
			margin-bottom: 0;
			margin-right: 6px;
			white-space: nowrap;
			width: unset;
		}
		.input {
			flex-direction: row;
		}

		.button,
		.mediumButton,
		.smallButton {
			align-self: flex-end;
		}
	}
}

.twoColumn {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 48px;
	row-gap: 16px;
	width: 100%;
	@include phone {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		column-gap: unset;
	}
	&.threeColumn {
		grid-template-columns: 1fr 1fr 1fr;
	}
	&.fourColumn {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		column-gap: unset;
	}
	> :global(.space) + :global(.space) {
		margin-top: 0;
	}
	&.center {
		justify-items: center;
	}
}

.separator {
	@include border($where: "left");
	margin: 0 16px;
	align-self: stretch;
}

.hrSeparator {
	@include border($where: "top");
	margin: 16px 0;
	width: 100%;
}

.container {
	.input {
		align-items: center;
		display: flex;

		&.labelLast {
			flex-direction: row-reverse;
			.label {
				margin-left: 11px;
			}
		}

		&:not(.disabled) > .label > span {
			cursor: pointer;
		}
	}

	.label {
		display: flex;
		width: 100%;
		margin-bottom: 4px;
	}

	.under {
		display: flex;
		margin-top: 4px;

		.error {
			padding-left: 12px;
			@include color("red");
		}

		.info {
			margin-left: auto;
			flex: 1;
			text-align: left;
			padding-left: 12px;
		}
	}
}

@mixin colorStyling($color, $hoverColor, $hoverFillColor, $fillColor: "white", $disabledColor: "grey-400") {
	$main: if($color == "black", "black", "#{$color}-primary");
	$borderColor: $main;

	@if $color == "grey" {
		$main: "black";
		$hoverColor: "blue-dark";
		$borderColor: "grey-400";
	}

	&.#{$color} {
		&:not(.customIcon) {
			@include color($fillColor, $svg: true, $background: $main, $borderColor: $borderColor);
		}
		&.customIcon {
			@include color($fillColor, $svg: false, $background: $main, $borderColor: $borderColor);
		}

		&:not(.loading):not(.disabled):hover {
			@include background($hoverColor);
			border-color: map.get($colors, $hoverColor);
		}

		&:not(.loading):not(.disabled).active {
			@include background($hoverFillColor);
		}

		&.disabled {
			@include background($disabledColor);

			&.border {
				border-color: map.get($colors, $disabledColor);
			}
		}
		&.invert {
			&:not(.customIcon) {
				@include color($main, $svg: true, $background: $fillColor, $borderColor: $borderColor);
			}
			&.customIcon {
				@include color($main, $svg: false, $background: $fillColor, $borderColor: $borderColor);
			}
			&:not(.loading):not(.disabled):hover {
				&:not(.customIcon) {
					@include color($hoverColor, $svg: true, $background: $hoverFillColor, $borderColor: $hoverColor);
				}
				&.customIcon {
					@include color($hoverColor, $svg: false, $background: $hoverFillColor, $borderColor: $hoverColor);
				}

				&:not(.border) {
					border-color: map.get($colors, $hoverFillColor);
				}
			}

			&.active:not(.disabled) {
				@include color($main, $svg: true, $background: $hoverFillColor, $borderColor: $borderColor);
			}

			&.disabled {
				&:not(.customIcon) {
					@include color($disabledColor, $svg: true, $background: $fillColor, $borderColor: $disabledColor);
				}
				&.customIcon {
					@include color($disabledColor, $svg: false, $background: $fillColor, $borderColor: $disabledColor);
				}
			}

			&:not(.border) {
				border-color: transparent !important;
			}
			&:not(.border):not(.active) {
				background-color: transparent;
			}
		}
	}
}

.smallButton,
.mediumButton,
.button {
	font-weight: 600;
	line-height: 150%;
	display: flex;
	align-items: center;
	position: relative;
	@include focus;
	gap: 8px;
	white-space: nowrap;
	&:focus-visible {
		outline-offset: -2px;
	}

	&:not(.disabled):not(.loading) {
		cursor: pointer;
	}

	@include border($color: "transparent");

	@include colorStyling("blue", "blue-dark", "blue-100");
	@include colorStyling("pink", "pink-dark", "pink-100");
	@include colorStyling("black", "grey-600", "transparent");
	@include colorStyling("grey", "grey-400", "transparent");

	&.large {
		width: 100%;
		justify-content: center;
	}
	&.noValue {
		padding: 7px;
	}
}

.button {
	@include radius;
	font-size: 16px;
	padding: 7px 23px;
	height: $inputHeight;
}

.mediumButton {
	@include radius(12px);
	font-size: 14px;
	padding: 11px 16px;
	height: 32px;

	.loadingDiv {
		margin: 0;
	}
}

.smallButton {
	@include radius(8px);
	font-size: 14px;
	padding: 7px 16px;
	height: 24px;

	.loadingDiv {
		margin: 0;
	}
}

.overlay {
	position: relative;
}
.loadingDiv {
	@include fill-absolute();
	@include background("white", 0.9);
	@include radius(14px);
	padding: 8px;
	margin: -1px;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		max-width: 100%;
		max-height: 100%;
		@include spin;
	}
}

.radio,
.checkbox {
	@include fill-absolute;
	margin: 0;
	padding: 0;
	height: 18px;
	width: 18px;
	opacity: 0;
	appearance: none;
	&:not(:disabled) {
		cursor: pointer;
	}
}

.radio {
	height: 24px;
	width: 24px;
}

.input.checkboxGroup {
	flex-direction: column;
	align-items: flex-start;

	> .label {
		margin-bottom: 16px;
	}
}

.inputBorder {
	@include border($radius: true);
	@include background("white");

	&:hover {
		@include border($color: "blue-dark");
	}

	&:focus-within,
	&.open {
		@include border($color: "blue-primary");
	}

	&.error {
		@include border($color: "red");
	}

	&.disabled {
		cursor: not-allowed;

		@include color("grey-600", $background: "grey-200", $borderColor: "grey-500");
		.text {
			@include color("grey-600", $background: "grey-200");
		}
	}
}

.textDiv {
	font-size: 16px;
	min-height: $inputHeight;
	padding: 7px 15px;
	width: 100%;
	display: flex;
	align-items: center;

	&.bare {
		padding: 0 10px;
		border-radius: inherit;
	}

	&.disabled {
		@include color("grey-600", $background: "grey-200", $borderColor: "grey-500");
		.text {
			@include color("grey-600", $background: "grey-200");
		}
	}

	.bold {
		font-weight: 600;
	}

	.text {
		border: none;
		width: 100%;
		height: 100%;
		outline: none;
	}

	.size2 {
		font-size: 18px;
	}
	.size4 {
		font-size: 14px;
	}

	.textArea {
		border: none;
		box-shadow: none;
		display: flex;
		outline: none;
		overflow: hidden;
		resize: none;
		width: calc(100% - 30px);
		&:focus {
			box-shadow: none;
			outline: none;
		}
	}
	&.bare {
		.textArea {
			width: 100%;
		}
	}

	.icon {
		@include svgPathColor("grey-600");
		padding-right: 8px;
	}

	.clear,
	.copy {
		@include svgPathColor("grey-600");
		@include focus;
		@include radius(3px);

		&:hover {
			@include svgPathColor("black");
		}
		&:focus-visible {
			outline-offset: -2px;
		}
	}
}

.select {
	grid-template-columns: 1fr 24px;
	padding: 7px 15px;

	> span {
		white-space: nowrap;
		padding-right: 8px;
		@include noSelection();
	}

	&.pillSelect {
		padding: 6px 16px;
	}
	.pillsContainer {
		@include border($where: "right");
		align-items: center;
		flex: 1;
		flex-direction: row;
		display: flex;
		margin-right: 4px;
		height: 100%;
	}
}

.select,
.searchableSelect {
	@include background("white");
	width: 100%;
	display: grid;
	align-items: center;
	min-height: $inputHeight;
	@include focus;
	white-space: nowrap;

	&:focus-visible {
		outline-offset: -2px;
	}

	.labels {
		align-content: space-between;
		flex-wrap: wrap;
		display: flex;
		flex: 1;
		gap: 8px;
		overflow: hidden;
	}
	.closeIcon {
		@include svgPathColor("grey-600");
		display: flex;
		padding: 0 4px;

		&:hover {
			@include svgPathColor("black");
		}
	}

	&:not(.disabled) {
		cursor: pointer;
	}
}

.selectInput {
	flex-direction: column;
	flex: 1;
}

.searchableSelectInput {
	align-items: center;

	.label {
		width: unset;
		margin-bottom: 0;
		> span {
			line-height: 100%;
		}
	}
}

.menuPortal {
	z-index: map.get($zIndices, "modal-container");
}
.dropdownIndicator {
	@include svgPathColor("black");

	&:hover {
		@include svgPathColor("grey-600");
	}
}
.separator {
	display: none;
}
.searchableSelect {
	padding: 7px;
	.text {
		border: none;
		outline: none;
		width: 100%;
		flex: 1;
		min-width: 30%;
	}
	.labels {
		align-content: center;
	}
}

.userSelect {
	@include focus;
	white-space: nowrap;
	user-select: none;
	display: flex;
	gap: 8px;
	align-items: center;

	&:not(.disabled) {
		cursor: pointer;
	}
}

.menu {
	overflow-y: auto;
	@include background;
	border-radius: 12px;
	min-width: 100px;
	@include boxShadow;
	z-index: map.get($zIndices, "modal-container");
	//DANGER: do not add padding here, it will cause infinite loop in searchable-select menu component
	//padding: 8px 0;

	.groupLabel {
		padding: 8px 16px;
		@include color("grey-600", $svg: true);
	}
	.option {
		padding: 8px 16px;
		cursor: pointer;
		line-height: 24px;
		display: flex;
		align-items: flex-start;
		flex-direction: row;
		gap: 8px;

		> span {
			@include noSelection();
		}

		&.active {
			@include background("grey-200");
		}

		&.selected {
			@include color("white", $background: "blue-primary");
			font-weight: 600;
			&.active {
				@include background("blue-dark");
			}
		}

		&.disabled {
			@include color("grey-400", $svg: true);
			pointer-events: none;
		}
	}
	.message {
		margin: 0 16px;
	}
}

.link {
	@include border($where: "bottom");
	align-items: center;
	display: flex;
	cursor: pointer;
	justify-content: flex-start;
	padding: 8px 16px;
}

.searchableSelectContainer {
	display: flex;
	align-items: center;
	// padding-left: 6px;
}
.reactSelect {
	flex: 1;
	width: 100%;
	.reactSelectControl {
		@include radius;
	}
	.bare {
		border: none;
		border-color: transparent;
	}
	.focused {
		border: none;
	}
	.focused:hover {
		border-color: transparent;
	}
}
.option:hover {
	@include background("grey-200");
}
.isFocused {
	@include background("grey-200");
}

.textInput {
	flex-direction: column;
	align-items: flex-start;
}

.textContainer {
	display: flex;
	flex-direction: column;
	flex: 1;
}

.switchContainer {
	justify-content: space-between;
	padding: 16px 0;

	&.condensed {
		padding: 8px 0;
	}

	& + & {
		@include border($where: "top");
		margin-top: 0;
	}

	.label {
		margin-bottom: 0;
	}

	/*
		The grid gap takes care of some of our spacing, so remove it everywhere
		except for the last two/three items inside of a <MultiColumn />
	*/
	@at-root .twoColumn &:not(:nth-last-child(-n + 2)) {
		padding-bottom: 0;
	}
	@at-root .threeColumn &:not(:nth-last-child(-n + 3)) {
		padding-bottom: 0;
	}
	@at-root .twoColumn &:nth-child(2) {
		border-top: none;
	}
	@at-root .threeColumn &:nth-child(3) {
		border-top: none;
	}
}

.checkboxInput {
	display: flex;
	align-items: center;
	$checkbox: &;

	input:focus-visible ~ div {
		@include outline;
		border-radius: 1px;

		&.radioSvg {
			outline-offset: 0;
			border-radius: 50%;
		}
	}

	.label {
		margin-bottom: 0;
		@include atContext($checkbox, ".disabled") {
			@include color("grey-600");
		}
	}

	.position {
		position: relative;
		svg {
			flex-shrink: 0;
		}

		@include svgPathColor("grey-500");
		@include atContext($checkbox, ".selected") {
			@include svgPathColor("pink-primary");
		}
		@include atContext($checkbox, ".disabled") {
			@include svgPathColor("grey-300");
		}
		@include atContext($checkbox, ":not(.disabled)") {
			&:hover {
				@include svgPathColor("pink-dark");
			}
		}
	}
}

.buttonMenu,
.dropdownDiv {
	@include background("white");
	@include radius(12px);
	@include boxShadow;

	padding: 8px 0;
	z-index: map.get($zIndices, "modal-container");
}

.buttonMenu {
	overflow-y: auto;
	min-width: 100px;

	.option {
		padding: 8px 16px;
		height: 40px;
		cursor: pointer;
		display: flex;
		align-items: center;
		flex-direction: row;
		gap: 8px;

		&.active {
			@include color("black-dark", $background: "grey-200");
		}
		&.disabled {
			opacity: 0.4;
		}

		&.selected {
			@include color($color: "white", $svg: true, $background: "blue-primary");
		}

		.switchContainer {
			padding: 0;
		}
	}

	.search {
		margin: 16px;
	}

	.message {
		margin: 0 16px;
	}
}

.emojiContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.emojiInput {
	display: flex;
	align-items: center;
	justify-content: center;

	.emoji {
		align-items: center;
		justify-content: center;
		padding: 8px;

		&:hover {
			@include color($color: "grey-600", $svg: true);
		}
		&.disabled {
			cursor: default;
			@include color($color: "grey-400", $svg: true);
		}
	}
}

.fileUploadContainer {
	@include click;
	display: flex;

	.fileUploadInput {
		width: 100%;
		height: 100%;

		.mainContainer {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 8px;
			justify-content: center;
			cursor: pointer;
			position: relative;
		}

		.uploadText {
			text-align: center;
		}

		.inputFile {
			display: none;
		}
		&.disabled {
			@include color($color: "grey-400", $svg: true);
		}
	}
}

.dropzone {
	@include radius(12px);
	border-color: map.get($colors, "grey-500");
	align-items: center;
	border-style: dashed;
	display: flex;
	flex-direction: column;
	gap: 16px;
	height: 270px;
	padding: 32px 25px;
	width: 100%;

	&.active,
	&.loading,
	&.success {
		@include background("blue-100");
		border-color: map.get($colors, "blue-primary");

		.defaultIcon {
			@include background("blue-primary");
		}
	}

	&.error {
		border-color: map.get($colors, "pink-primary");
	}

	.defaultIcon {
		@include background("grey-500");
		@include radius(50%);
		padding: 10px;
	}

	.main {
		display: flex;
		flex-direction: column;
		gap: 8px;

		.text {
			align-items: center;
			display: flex;
			flex-direction: column;

			h5 {
				@include color("blue-primary");
			}

			.errorMessage {
				@include color("pink-primary");
			}

			h6 {
				@include color("grey-500");
			}
		}

		.item {
			align-items: center;
			display: flex;
			flex-direction: row;
			gap: 8px;
			justify-content: center;

			.line {
				@include border($where: [ "top" ], $color: "grey-500");
				height: 1px;
				width: 75px;
			}
		}
	}
}

.groupedDropdown {
	@include background;
	@include boxShadow;
	@include radius(12px);
	min-width: 100px;
	overflow-y: auto;
	padding: 8px 0;
	z-index: map.get($zIndices, "modal-container");

	.label {
		margin: 0 15px;
	}

	.options {
		cursor: pointer;
		align-items: center;
		display: flex;
		height: 40px;
		padding: 8px 16px;

		&.active {
			@include color("black-dark", $background: "grey-200");
		}
	}
}

.colorPicker {
	display: flex;
	align-items: center;
	flex-direction: column;

	.color {
		display: flex;
		margin-top: 12px;
		width: 200px;
		align-items: flex-end;

		.colorButton {
			@include border($color: "grey-300", $width: 5px);
			cursor: pointer;
			height: 36px;
			width: 34px;
		}

		.colorInput {
			display: flex;
			flex-direction: column;
			margin-left: 12px;
		}
	}

	div[class="react-colorful"] {
		margin-bottom: 12px;

		div[class="react-colorful__saturation"] {
			border-radius: 0;
			margin-bottom: 8px;
		}

		div[class="react-colorful__hue"],
		div[class*="react-colorful__last-control"] {
			@include radius();
			height: 16px;
			margin-top: 12px;
		}

		div[class*="react-colorful__hue-pointer"],
		div[class*="react-colorful__saturation-pointer"] {
			width: 20px;
			height: 20px;
		}
	}
}

.toolbar {
	display: none;
	align-self: flex-start;

	&.show {
		display: initial;
	}

	.icon {
		@include svgPathColor("black");
		&:hover {
			@include svgPathColor("blue-primary");
		}
	}
}
.richText {
	width: 100%;
}

.header {
	display: flex;
	align-items: center;
	gap: 10px;
}

.treeItem {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px 16px;

	.treeInfo {
		display: flex;
		align-items: center;
		gap: 10px;

		&.subItem {
			margin-left: 32px;
		}

		.icon {
			@include svgPathColor("grey-600");
		}
	}

	.treeLabel {
		display: flex;
		flex-direction: column;
	}
}

.radioButtonsHorizontal {
	display: flex;
	gap: 20px;
	:global(.space) + :global(.space) {
		margin-top: 0;
	}
}

.groupedDropdownItem {
	padding: 4px 16px;
}

.mentionsText {
	flex-direction: row;
	padding: 7px 15px;
	position: relative;
	.mentions {
		width: 100%;
		textarea {
			&:focus {
				outline: none;
			}
		}
		strong {
			text-shadow: -0.25px 0 #2e394a, 0 0.25px #2e394a, 0.25px 0 #2e394a, 0 -0.25px #2e394a;
		}
		.mentionsInputSuggestions {
			@include boxShadow;

			max-height: 150px;
			overflow-y: auto;

			.mention-user {
				min-width: 100px;
				padding: 10px;

				.account {
					display: flex;
					padding-left: 2px;
					column-gap: 10px;
				}

				&.focused {
					@include background("grey-200");
				}
			}
		}
	}
}
