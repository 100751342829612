@use "sass:map";
@use "sass:list";
@use "sass:color";

@mixin atContext($context, $addition) {
	@at-root #{selector-replace(&, $context, $context + $addition)} {
		@content;
	}
}

// prettier-ignore
$colors: (
	"white":        #FFFFFF,
	"grey-100":     #FAFBFC,
	"grey-200":     #EEF1F5,
	"grey-300":     #DCE0E7,
	"grey-400":     #C4CAD4,
	"grey-500":     #A1ADBF,
	"grey-600":     #818B9A,
	"black":        #2E394A,
	"black-dark":   #161C25,

	"pink-primary": #D32B79,
	"pink-primary-alpha":    color.change(#D32B79, $alpha: .3),
	"pink-100":     #FCDAE7,
	"pink-dark":    #A92362,

	"blue-primary": #0997D9,
	"blue-primary-alpha":    color.change(#0997D9, $alpha: .3),
	"blue-50":      #5AC2F7,
	"blue-50-alpha":     color.change(#5AC2F7, $alpha: .3),
	"blue-100":     #DAF2FC,
	"blue-dark":    #056490,

	"red":          #CA0000,
	"orange":       #F09D0A,
	"yellow":       #F0D90A,
	"green":        #38B86E,
	"green-alpha":    color.change(#38B86E, $alpha: .3),
	"green-100":    #00BF7D,
	"green-100-alpha":    color.change(#00BF7D, $alpha: .3),

	"logo-pink":    #E72B81,
	"logo-blue":    #818B9A,
	"logo-both":    #2AB9FB,

	"transparent":  rgba(0, 0, 0, 0),
);

$zIndices: (
	"last-stack": 1,
	"navbar-submenu": 100,
	"modal-container": 1000,
	"menu": 1001,
	"toast-container": 1002,
	"tooltips": 1003,
);

$sizes: (
	"nano": 24px,
	"micro": 30px,
	"mini": 50px,
	"smaller": 72px,
	"small": 80px,
	"medium": 150px,
	"large": 250px,
);

@mixin fill-absolute($margin: 0) {
	position: absolute;
	left: $margin;
	right: $margin;
	top: $margin;
	bottom: $margin;
}

@mixin size() {
	@each $name, $size in $sizes {
		&.#{$name} {
			height: $size;
			width: $size;
		}
	}
}

@mixin radius($radius: 15px, $where: []) {
	@if list.length($where) == 0 {
		border-radius: $radius;
	} @else {
		@each $side in $where {
			@if $side == "top" {
				border-top-left-radius: $radius;
				border-top-right-radius: $radius;
			} @else if $side == "bottom" {
				border-bottom-left-radius: $radius;
				border-bottom-right-radius: $radius;
			} @else if $side == "left" {
				border-top-left-radius: $radius;
				border-bottom-left-radius: $radius;
			} @else if $side == "right" {
				border-top-right-radius: $radius;
				border-bottom-right-radius: $radius;
			}
		}
	}
}

@mixin border($radius: false, $style: solid, $where: [], $color: "grey-300", $width: 1px) {
	@if list.length($where) == 0 {
		border: $width $style map.get($colors, $color);
	} @else {
		@each $b in $where {
			border-#{$b}: $width $style map.get($colors, $color);
		}
	}

	@if $radius {
		@include radius;
	}
}

$phone-min: 414px;
$tablet-min: 768px;
$desktop-min: 1024px;

@mixin phone {
	@media (max-width: #{$tablet-min - 1px}) {
		@content;
	}
}

@mixin tablet {
	@media (min-width: #{$tablet-min}) and (max-width: #{$desktop-min - 1px}) {
		@content;
	}
}

@mixin desktop {
	@media (min-width: #{$desktop-min}) {
		@content;
	}
}

@mixin tablet-and-desktop {
	@media (min-width: #{$tablet-min}) {
		@content;
	}
}

@mixin tablet-and-phone {
	@media (max-width: #{$desktop-min - 1px}) {
		@content;
	}
}

@mixin fix-min {
	/*
		Firefox sometimes likes to expand certain containers out to the content's
		sizing and this stops it. Unsurprisingly, this is due to incomplete and/or
		changing CSS specs.
		It's a mixin because this reason is less than obvious.
		https://moduscreate.com/blog/how-to-fix-overflow-issues-in-css-flex-layouts/
	*/
	min-height: 0;
	min-width: 0;
}

@mixin fix-drag-container {
	/*
		Because we're using React DnD we need to
		Add transform: translate(0, 0); to the node you wish to drag as a preview.
		https://stackoverflow.com/questions/22922761/rounded-corners-with-html-draggable/49523799#49523799
	*/
	transform: translate(0, 0);
}

@mixin background($color: "white", $alpha: 1) {
	@if $color == "transparent" {
		background: transparent;
	} @else {
		background: color.change(map.get($colors, $color), $alpha: $alpha);
	}
}

@mixin color($color, $svg: false, $background: false, $borderColor: null) {
	color: map.get($colors, $color);
	@if $svg {
		svg path,
		rect {
			fill: map.get($colors, $color);
		}
	}
	@if $background {
		background: map.get($colors, if($background == true, $color, $background));
	}
	@if $borderColor {
		border-color: map.get($colors, if($borderColor == true, $color, $borderColor));
	}
}

@mixin svgPathColor($color) {
	svg path {
		fill: map.get($colors, $color);
	}
	svg path[fill="#FFF"] {
		fill: #fff !important;
	}
}

@mixin boxShadow($alpha: 0.12, $color: "black") {
	box-shadow: 0 2px 10px color.change(map.get($colors, $color), $alpha: $alpha);
}

@mixin spin {
	:global {
		animation: pathRotate 2.5s infinite linear;
	}
}

@mixin trim($numLines: 1) {
	overflow: hidden;

	@if $numLines > 1 {
		display: -webkit-box;
		-moz-box-orient: vertical;
		-webkit-line-clamp: $numLines;
		-webkit-box-orient: vertical;
	} @else {
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

@mixin outline {
	outline: 2px solid map.get($colors, "blue-dark");
	outline-offset: 1px;
}

@mixin focus {
	&:focus-visible {
		@include outline;
	}
}

@mixin click {
	&.onClick {
		cursor: pointer;
	}
}

@mixin noSelection {
	user-select: none;
}

@mixin more-items-modal {
	.items {
		display: flex;
		flex-direction: column;
		min-height: 300px;
		padding-top: 16px;

		.row {
			display: flex;
			flex-direction: row;
			padding: 6px 8px;
			gap: 8px;

			@include tablet-and-phone {
				flex-direction: column;
			}

			&:nth-child(odd) {
				@include background("grey-100");
			}

			&:first-child {
				background: none;
				padding-bottom: 16px;
				@include border($where: "bottom");
			}

			.rowItem {
				display: flex;
				flex-flow: row nowrap;
				flex: 1;
				overflow-wrap: anywhere;
			}
		}
	}
}
