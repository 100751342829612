@import "../../../global.scss";

.postPage {
	position: absolute;
	display: flex;
	background: #eef1f5;
	height: calc(100% - 72px);
	overflow: hidden;
	justify-content: center;
	padding: 24px;

	.postContainer {
		display: flex;
		flex-direction: row;
		justify-content: center;
		gap: 32px;
		position: relative;
		width: 100%;

		.hidableContainer {
			display: flex;
			flex-direction: column;
			gap: 16px;
			align-items: center;
			height: 100%;
			@include background("grey-200");
			width: 448px;
			&.floating {
				position: absolute;
				right: 10px;
				top: 65px;
				@include boxShadow;
				@include radius;
				max-height: calc(100% - 50px);
				width: 464px;
				> div {
					padding: 0 16px;
				}
			}

			.advancedTabs {
				display: flex;
				justify-content: center;

				.separator {
					@include color("grey-300");
					@include border($color: "grey-300", $width: 1px, $where: "right");
					margin: 8px;
				}
			}
		}

		.post {
			display: flex;
			flex-direction: column;
			overflow: hidden;
			gap: 16px;
			width: min(95%, 800px);
		}

		.control {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.advancedBtn {
				display: flex;
				flex-flow: row-reverse;
				height: 40px;
				padding: 8px 0;
				gap: 8px;
				border-radius: 15px 0px 0px 0px;

				&.advanced {
					flex-flow: row;
					width: auto;
				}
				.advancedBtnText {
					font-size: 16px;
					font-weight: 700;
					line-height: 24px;
					text-align: center;
				}
				.advancedBtnIcon {
					svg {
						transform: rotate(-90deg);
					}
				}
			}
		}

		.postHeader {
			font-size: 24px;
			font-weight: 700;
			align-content: center;
			display: block;
			width: 275px;
		}
	}
}

.postModal {
	background: transparent;
	.postContainer {
		height: 90vh;
		overflow-y: auto;
		min-width: min(720px, 90vw);
		max-width: 800px;
	}
}

.loadingPost {
	background: white;
	position: relative;
	display: flex;
	border-radius: 25px;
	justify-content: center;
	min-height: min(400px, 90vh);
	min-width: min(720px, 90vw);
}
