@import "../../../global.scss";

.postModal {
	background: transparent;
	.postContainer {
		height: 90vh;
		overflow-y: auto;
		min-width: min(800px, 90vw);
		max-width: 800px;
	}
}

.loadingPost {
	background: white;
	position: relative;
	display: flex;
	border-radius: 25px;
	justify-content: center;
	min-height: min(400px, 90vh);
	min-width: min(720px, 90vw);
}
