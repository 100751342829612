@import "../../global.scss";

.list {
	margin-top: 16px;
	display: flex;
	flex-direction: column;
	gap: 16px;
}
.card {
	padding: 32px 40px;
	box-shadow: 0 2px 10px #2e394a1f;
	background: #fff;
	border-radius: 15px;
}
.top {
	display: flex;
}
.arrow {
	align-items: center;
	cursor: pointer;
	display: flex;
	margin-left: 16px;
	min-height: 40px;
	padding-left: 16px;
	border-left: 1px solid #dce0e7;
}

.header {
	display: flex;
	align-items: center;
	column-gap: 16px;
	flex-grow: 1;
	gap: 16px;
	.nameText {
		flex: 2;
	}
	.textItem {
		min-width: 152px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
}
.hidden {
	visibility: hidden;
}

.columns {
	display: flex;
	gap: 32px;
	margin-bottom: 48px;
	.divider {
		@include border($where: "left");
	}
	.members {
		flex: 1;
		.addUser {
			max-width: 360px;
			display: flex;
			flex-direction: column;
			gap: 4px;
		}
		.checkboxAll {
			margin-left: 25px;
		}
		.userList {
			height: 400px;
			overflow-y: auto;
			.userBox {
				display: flex;
				align-items: center;
				@include border($radius: true);
				padding: 16px 24px;
				.userVA {
					margin-left: auto;
					margin-right: 40px;
				}
			}
		}
		.removeMultiple {
			@include color("pink-primary", $svg: true);
			display: flex;
			align-items: center;
			gap: 8px;
			position: relative;
		}
	}
	.permissions {
		display: flex;
		flex-direction: column;
		width: 390px;
	}
	.flexColumn {
		flex-direction: column;
		align-items: flex-start;
		gap: 4px;
	}
}
