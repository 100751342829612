@import "../../../../global.scss";

.postsPreview {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 24px;
	overflow-x: hidden;
	overflow-y: auto;
	padding-right: 16px;

	.postContainer {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 8px;
		max-width: 432px;

		.headline {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 16px;

			.collapseIcon {
				cursor: pointer;
			}
		}

		.invalidPost {
			display: flex;
			flex-direction: column;
			gap: 8px;
			padding: 16px;
			@include border($color: "red");
			@include radius(8px);
			@include background("red", 0.1);
		}
		.postCard {
			padding: 16px 0;
			display: flex;
			flex-direction: column;
			gap: 8px;
			margin: 8px 16px;

			&.border {
				@include border($color: "grey-300");
				@include radius(10px);
			}

			&.filled {
				@include background($color: "white");
			}

			.avatar {
				@include background("black");
				@include color("white");
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				flex-shrink: 0;
				width: 40px;
				height: 40px;
				border-radius: 50%;
				img {
					width: 40px;
					height: 40px;
					border-radius: 50%;
					object-fit: cover;
				}
			}

			.header {
				display: flex;
				gap: 8px;
				align-items: center;
				padding: 0 16px;

				.info {
					.timePrivacy {
						display: flex;
						gap: 4px;
					}
				}
			}

			.content {
				display: flex;
				flex-direction: column;
				gap: 8px;

				.comment {
					padding: 0 16px;
				}
			}

			&.linkedIn {
				.articleContainer {
					margin: 0 16px;
					padding: 8px;
					@include radius(8px);
					@include border($color: "grey-300");
					.article {
						display: flex;
						gap: 8px;
						overflow: hidden;
						align-items: center;

						img {
							@include radius(8px);
							max-width: 128px;
							height: 72px;
							object-fit: cover;
						}

						.articleInfo {
							overflow: hidden;
						}
					}
				}

				.footer {
					display: flex;
					flex-direction: column;
					gap: 8px;
				}
				.engagement {
					@include border($color: "grey-300", $where: "bottom");
					font-size: 12px;
					display: flex;
					justify-content: right;
					padding-bottom: 8px;
					margin: 0 16px;
				}
			}

			&.facebook {
				.articleContainer {
					display: flex;
					flex-direction: column;
					gap: 8px;
					@include background("grey-200");
					@include border($color: "grey-300", $where: "bottom");
					padding-bottom: 4px;

					img {
						width: 100%;
						object-fit: cover;
					}

					.articleInfo {
						display: flex;
						flex-direction: column;
						gap: 4px;
						padding: 0 16px;

						.link {
							text-transform: uppercase;
						}
					}
				}

				.toolbar {
					justify-content: space-evenly;
				}
			}

			&.twitter {
				display: flex;
				flex-direction: row;
				gap: 8px;
				padding-top: 0;

				.postBody {
					display: flex;
					flex-direction: column;
					gap: 4px;
					width: calc(100% - 48px);

					.content {
						gap: 8px;

						img,
						video {
							@include radius(12px);
						}
					}
				}
				.header {
					padding: 0;
					justify-content: space-between;
					.info {
						display: flex;
						flex-direction: row;
						gap: 4px;
						align-items: center;
					}
				}
				.comment {
					padding: 0;
				}
				.articleContainer {
					display: flex;
					flex-direction: column;
					padding-bottom: 4px;
					position: relative;
					overflow-x: hidden;

					img {
						width: 100%;
						object-fit: cover;
						@include radius(12px);
					}

					.titleContainer {
						position: absolute;
						bottom: 30px;
						overflow: hidden;
						margin: 0 10px;
						width: 300px;
						@include radius(4px);
						padding: 0 4px;
						@include background("black", 0.9);

						.title {
							display: block;
							color: #fff;
						}
					}
				}

				.toolbar {
					padding: 0;
				}
			}

			&.tiktok {
				position: relative;
				padding: 0;
				@include radius(8px);
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				width: 325px;
				height: 565px;
				@include background("black");

				.postBody {
					position: absolute;
					bottom: 0;
					display: flex;
					justify-content: space-between;
					padding: 12px;
					width: 100%;

					.content {
						justify-content: flex-end;
						padding: 0;
						gap: 4px;
						* {
							color: #fff;
						}

						.comment {
							padding: 0;
						}
					}
					.toolbar {
						flex-direction: column;
						padding: 0;
						gap: 4px;
						.avatar {
							img {
								@include border($color: "white");
							}

							&.profilePic {
								//width: 32px;
								//height: 32px;
								background: radial-gradient(
									circle,
									rgb(21, 21, 21) 7%,
									rgb(57, 57, 57) 28%,
									rgb(22, 22, 22) 43%,
									rgb(57, 57, 57) 64%
								);

								img {
									width: 28px;
									height: 28px;
									border: none;
								}
							}
						}
					}
				}
				img,
				video {
					align-self: center;
					width: 100%;
					object-fit: cover;
				}
			}

			&.instagram {
				position: relative;
				padding: 0;
				@include radius(8px);
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				width: 325px;
				height: 565px;
				@include background("black");

				.postBody {
					position: absolute;
					bottom: 0;
					display: flex;
					justify-content: space-between;
					padding: 12px;
					width: 100%;

					.content {
						justify-content: flex-end;
						padding: 0;
						gap: 8px;
						* {
							color: #fff;
						}

						.userInfo {
							display: flex;
							gap: 8px;
							align-items: center;

							.avatar {
								img {
									width: 32px;
									height: 32px;
								}
							}
						}

						.comment {
							padding: 0;
						}
					}
					.toolbar {
						flex-direction: column;
						padding: 0;

						.avatar {
							img {
								width: 28px;
								height: 28px;
								@include radius(4px);
								@include border($color: "white");
							}
						}
					}
				}
				img,
				video {
					align-self: center;
					width: 100%;
					object-fit: cover;
				}
			}

			.toolbar {
				display: flex;
				justify-content: space-between;
				align-items: center;
				gap: 16px;
				padding: 0 16px;

				.button {
					display: flex;
					align-items: center;
					gap: 8px;
					padding: 4px;
				}
			}
		}
	}

	.previewInfo {
		display: flex;
		align-items: flex-start;
		gap: 8px;
	}
}
