@import "../../../global.scss";

.loadingPost {
	background: white;
	position: relative;
	display: flex;
	border-radius: 25px;
	justify-content: center;
	min-height: min(400px, 90vh);
	min-width: min(720px, 90vw);
}

.selectNetworks {
	display: flex;
	flex-direction: column;
	min-width: 450px;
	position: relative;
	@include background("grey-100");
	overflow: hidden;
	max-height: calc(100% - 50px);
	@include radius($radius: 16px);
	@include boxShadow($alpha: 0.04);

	.header {
		padding: 40px 32px 32px;
	}

	.body {
		padding: 0 32px;

		.networksHeader {
			display: flex;
			margin-bottom: 16px;
			justify-content: space-between;
			.networks {
				display: flex;
				align-items: center;
				column-gap: 8px;

				.network {
					padding: 7px;
					@include radius($radius: 8px);
					@include border($color: "grey-300", $width: 1px);
					cursor: pointer;
					&.selected {
						border-color: #0997d9;
						background: #daf2fc;
					}
				}
			}
		}

		.post {
			background: white;
			display: flex;
			flex-direction: column;
			gap: 16px;
			@include radius($radius: 16px);
			@include border($color: "grey-300");
			padding: 16px 24px;
			.mediaContainer {
				position: relative;
				display: flex;
				align-self: stretch;
			}
			.postContent {
				@include background("grey-200");
				@include radius(8px);
				width: 100%;
				padding: 16px;
				display: flex;
				gap: 16px;
			}
			.postContentText {
				display: flex;
				flex: 1 1;
				flex-direction: column;
				gap: 8px;
				overflow: hidden;
				a {
					@include color("grey-600");
				}
			}
			.postUrl {
				cursor: pointer;
				&:hover {
					text-decoration: underline;
				}
			}
			.image {
				display: flex;
				width: 128px;
				height: 96px;
				flex-shrink: 0;
				align-self: stretch;
				img {
					width: 100%;
					object-fit: cover;
					border-radius: 8px;
				}
			}
		}
		.imageList {
			display: flex;
			gap: 7px;
			.image {
				width: 96px;
				height: 96px;
				@include border($color: "grey-300", $radius: 8px);
			}
		}
	}

	.footer {
		margin-top: 32px;
		padding: 32px 40px;
		background: #fff;
		// border-top: 1px solid #dce0e7;
		@include border($where: "top", $color: "grey-300");
	}
}
